let handleBase64 = (file) => {
    return new Promise((resolve) => {
        let reader = new FileReader();
        reader.onload = function () {
            let BASE64 = this.result.split(",");
            resolve(BASE64[1]);
        }
        reader.readAsDataURL(file);
    })
}

export { handleBase64 }