<template>
	<div class="campus-box">
		<!-- 是否认证 -->
		<div class="campus-title">
			<!-- 已认证 -->
			<div class="campus-title-success" v-if="is_certed == 1">
				<i class="el-icon-circle-check"></i>
				<span>已认证</span>
			</div>
			<!-- 未认证 -->
			<div class="campus-title-error" v-else>
				<span>未认证</span>
			</div>
		</div>
		<!-- 表单 -->
		<el-form :model="form" label-width="300px" label-position="left" class="campus-form" size="small">
			<!-- 姓名 -->
			<el-form-item label="姓名 / Name" prop="">
				<el-input maxlength="10" v-model="form.name" class="campus-input">
				</el-input>
			</el-form-item>
			<!-- 学校 -->
			<el-form-item maxlength="10" label="学校 / School" prop="">
				<el-input v-model="form.campus" class="campus-input">
				</el-input>
			</el-form-item>
			<!-- 入学年份 -->
			<el-form-item label="入学年份 / Date" prop="">
				<el-date-picker v-model="form.enrol" type="month" value-format="yyyy-MM" class="campus-input">
				</el-date-picker>
			</el-form-item>
			<!-- 上传身份证 -->
			<el-form-item label="上传学生证 / Student ID card" prop="">
				<div style="color: #E6A23C">建议图片大小2M以内</div>
				<el-row>
					<el-col :span="12">
						<el-upload action="#" list-type="picture" :auto-upload="false" :multiple="false"
							:show-file-list="false" :on-change="handleUpload" class="campus-upload">
							<el-image :src="form.stucard" v-if="form.stucard" fit="scale-down" lazy
								class="campus-upload-pic">
								<!-- 加载中样式 -->
								<template v-slot:placeholder>
									<div class="image-slot">
										<i class="el-icon-loading"></i>
									</div>
								</template>
								<!-- 加载失败样式 -->
								<template v-slot:error>
									<div class="image-slot">
										<i class="el-icon-picture-outline"></i>
									</div>
								</template>
							</el-image>
							<!-- 加号 -->
							<i v-else slot="default" class="el-icon-plus campus-upload-icon"></i>
						</el-upload>
					</el-col>
				</el-row>
			</el-form-item>
			<!-- 按钮 -->
			<el-form-item>
				<el-button v-if="is_certed!=1" type="warning" size="medium" @click="handleSubmit(form)">
					<span v-if="is_certed == 2">重新提交</span>
					<span v-else>提交</span>
				</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
	import {
		mapState,
		mapMutations,
		mapActions
	} from "vuex";
	import {
		handleBase64
	} from "@/tool/publicMethod.js";

	export default {
		data() {
			return {
				is_certed: 2,
				form: {
					name: "",
					campus: "",
					enrol: "",
					stucard: "",
				},
				pic_file: {},
			}
		},
		computed: mapState({
			cer: state => state.user.certification,
		}),
		methods: {
			...mapMutations([
				"setCertification"
			]),
			...mapActions([
				"handleUploadImage",
				"getCertification",
				"handleSubmitCertification"
			]),
			setForm(val) {

				if (val.certed === 1) {
					this.is_certed = 1;
					this.form = {
						name: val.name,
						campus: val.campus,
						enrol: val.enrol,
						stucard: val.stucard,
					}
					this.$alert('你已认证', '标题名称', {
						confirmButtonText: '确定',
						callback: action => {

						}
					});
				} else {
					this.is_certed = val.certed;
					this.form = {
						name: val.name || "",
						campus: val.campus || "",
						enrol: val.enrol || "",
						stucard: val.stucard || "",
					}
				}
			},
			handleUpload(file, fileList) { // 学生证上传
				const isLt2M = file.size / 1024 / 1024 < 2;
				if (!isLt2M) {
					this.$message.error('上传图片大小不能超过 2MB!');
					return;
				}
				handleBase64(file.raw)
					.then(this.handleUploadImage)
					.then(res => {
						if (res.type) this.form.stucard = res.result;
						this.$message({
							showClose: true,
							message: res.msg,
							type: res.type ? "success" : "error"
						})
					})
			},
			handleSubmit(form) {
				this.handleSubmitCertification({
					name: form.name,
					campus: form.campus,
					enrol: form.enrol,
					stucard: form.stucard,
				}).then(res => {
					// this.$message({
					//     showClose: true,
					//     message: res.msg,
					//     type: res.type? "success": "error"
					// })
					this.$alert(res.msg, '提示', {
						confirmButtonText: '确定'
					})
				})
			}
		},
		watch: {
			cer: {
				handler: "setForm",
				immediate: true
			}
		},
		beforeRouteEnter(to, from, next) {
			next(vm => {
				vm.getCertification()
			})
		},
		beforeRouteLeave(to, from, next) {
			this.setCertification({});
			next();
		}
	}
</script>

<style scoped>
	.campus-box {
		padding: 20px 50px;
	}

	/* 是否认证标题盒子 */
	.campus-title {
		margin-bottom: 30px;
	}

	.campus-title>div {
		display: inline-block;
		padding: 5px 20px;
		border-radius: 20px;
		color: #fff;
	}

	/* 未认证 */
	.campus-title-error {
		background-color: #E6A23C;
	}

	/* 认证成功 */
	div.campus-title-success {
		font-weight: bold;
		color: #67C23A;
	}

	.campus-form>>>.el-form-item__label {
		color: #909399;
	}

	/* 输入框大小 */
	.campus-input {
		width: 220px;
	}

	/* 学生证图片 */
	.campus-upload {
		position: relative;
		width: 90%;
	}

	.campus-upload::before {
		display: block;
		content: "";
		padding-top: 65%;
	}

	.campus-upload>>>.el-upload {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		cursor: pointer;
		border: 2px solid #8d9afc;
		border-radius: 6px;
		overflow: hidden;
	}

	.campus-upload-pic {
		width: 100%;
		height: 100%;
	}

	.campus-upload-icon {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: 28px;
		color: #8d9afc;
	}

	.campus-upload-text {
		text-align: center;
	}
</style>
